body {
  margin: 0px;
}

.video-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0px solid transparent;
}
