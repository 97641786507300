@font-face {
  font-family: "Archivo-Medium";
  src: url("../assets/fonts/Archivo-Medium.ttf");
}

@font-face {
  font-family: "Archivo-Regular";
  src: url("../assets/fonts/Archivo-Regular.ttf");
}

@font-face {
  font-family: "Lato-Light";
  src: url("../assets/fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat-VariableFont_wght.ttf");
}


