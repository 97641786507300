@import "../../global/index.scss";

.section__contact {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  padding: 50px 0;
  width: 100%;
  position: relative;
  display: flex;
  background-image: url("../../../public/assets/image/banner_contact.jpg");
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(76, 132, 167, 0.87);
  }
  .title__contact h4 {
    color: #fff;
    font-size: 3rem;
    z-index: 1;
    font-weight: 400;
    margin: 0px;
    padding: 50px 0 25px 0;
  }
  .input__contact {
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid #fff;
    div {
      background-color: #fff;
      border-radius: 25px;
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
  }
  .container__btn__contact {
    padding-bottom: 25px;
    button {
      width: 100%;
      background-color: #fff;
      padding: 10px 35px;
      border-radius: 25px;
      border: 2px solid $secondary;
      transition: 0.5s;
      text-decoration: none;
      text-transform: none;
      color: $secondary;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.75;
      margin: 0px;
      &:hover {
        opacity: 0.75;
      }
    }
  }
}
