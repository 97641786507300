@import "../../global/index.scss";
* {
  font-family: "Montserrat" !important;
}
/* /------------------- BREAKPOINTS -------------------/ */
.hidden_mobile {
  display: none!important;
}
/* /------------------- MD > 600px / TABLET -------------------/ */
@media (min-width: $md) {
  .hidden_mobile {
    display: block!important;
  }
}

/* /------------------- LG > 920 px / DESKTOP LARGE -------------------/ */
@media (min-width: $lg) {
}

@media (max-width: $lg) {
  .navbar__menu {
    div {
      width: 100% !important;
    }
    .language-mobile {
      position: absolute !important;
      top: 0 !important;
      width: 150px !important;
      right: 0 !important;
      margin: 25px !important;
    }
  }
}

/* /------------------- XL > 1536px / DESKTOP EXTRA LARGE -------------------/ */
@media (min-width: $xl) {
}

/* /------------------- BREAKPOINTS -------------------/ */
.navbar__main {
  padding: 15px 40px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%) !important;
  background: #ffffff !important;
  min-height: 115px;
  display: flex;
  justify-content: center;
  z-index: 100!important;
  &.no-transparent {
    transition: 0.5s;
    background: $primary !important;
    padding: 12px 0;
  }
  .navbar__content {
    padding-left: 0px;
    div {
      display: flex !important;
      align-items: center !important;
      justify-content: center;
      .navbar__logo {
        max-height: 50px;
      }
    }
    .menu-mobile {
      color: $primary;
    }
    .items-navbar a {
      color: $primary;
      text-decoration: none;
      padding: 0 10px;
      transition: 0.5s;
      &:hover {
        opacity: 0.7;
      }
    }
    .navbar__menu__active span {
      color: $secondary !important;
    }

    @media (max-width: $lg-navbar) {
      .items-navbar {
        display: none !important;
      }
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  border: transparent !important;
}

.text-link {
  text-decoration: none;
  color: #1f3934;
}

.modal-center {
  text-decoration: none;
  text-align: center;
  color: #1f3934;
}

.navbar__menu {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    .navbar__menu__header {
      position: absolute;
      top: 0;
      left: 0;
      justify-content: flex-start;
      padding: 0px;
      button {
        margin: 0 8px;
      }
    }
    ul {
      width: 100%;
      .navbar__menu__active span {
        color: $secondary !important;
      }
      a {
        color: $primary;
        text-decoration: none;
        .navbar__menu__active span {
          color: $secondary !important;
        }
        .navbar__submenu__active span {
          color: $secondary !important;
        }
      }
      li {
        a {
          padding-left: 15px;
          width: 95%;
          height: 100%;
        }
        div {
          justify-content: flex-start;
        }
      }
      .navbar__menu__option__collapse div {
        padding-left: 15px;
        justify-content: flex-start;
      }
    }
    .navbar__menu__collapse div div {
      flex-direction: column;
      align-items: flex-start;
      a {
        padding-left: 25px;
        width: 95%;
        height: 100%;
      }
    }
    .navbar__menu__footer {
      position: absolute;
      bottom: 0;
      margin: 25px 0;
      flex-direction: column;
      p {
        font-size: 0.8rem;
        color: $grey-secondary;
      }
    }
  }
}

.container__center { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section {
  padding: 5% 5%;
  .section__container__img {
    min-height: 500px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: contain;
  }
  .section__container__text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h4 {
      color: $brown-primary;
      font-size: 1.5rem;
    }
    p {
      width: 100%;
      color: $primary;
      font-size: 1rem;
      line-height: 1.75;
    }
    &.start {
      justify-content: flex-start !important;
    }
    &.text__center {
      text-align: center;
    }
    .margin__bottom {
      margin-bottom: 35px;
    }
    .list__item {
      margin: 0 0 25px 35px;
      div {
        position: absolute;
        svg {
          margin: 2.5px 0 0 -35px;
        }
      }
    }
  }
  &.grey-color {
    background-color: #f9f9f9;
  }
  &.brown-color {
    background-color: #d1be92;
    padding: 60px 30px;
  }
  @media (min-width: $lg) {
    .right {
      padding-left: 50px !important;
    }
    .section__container__text {
      h4 {
        font-size: 2rem !important;
      }
      p {
        font-size: 1rem !important;
        margin-top: -20px;
      }
    }
  }
}

/* /------------------- BREAKPOINTS -------------------/ */

/* /------------------- MD > 600px / TABLET -------------------/ */
@media (min-width: $md) {
  .banner_container {
    .text-banner-home {
      font-size: 2.5rem;
    }
    .text-banner-other {
      font-size: 2.5rem;
    }
  }
  .banner_container_carousel {
    .text-banner-home {
      font-size: 2.5rem;
    }
    .text-banner-other {
      font-size: 2.5rem;
    }
  }
  .choose__title {
    font-size: 2.5rem;
  }
  .pillars__title {
    font-size: 2.5rem;
  }
  .phases__title {
    font-size: 2.5rem;
  }

  .title__question {
    font-size: 1.8rem;
  }
}

@media (min-width: 1200px) {
  .description__item {
    height: 200px!important;
  }
}

@media (min-width: 900px) {
  .description__item {
    height: 250px;
  }
  .description__item__photo {
    min-height: 200px !important;
  }
}

/* /------------------- LG > 920 px / DESKTOP LARGE -------------------/ */
@media (min-width: $lg) {
  .banner_container {
    .text-banner-home {
      font-size: 3rem;
    }
    .text-banner-other {
      font-size: 3rem;
    }
  }

  .banner_container_carousel {
    .text-banner-home {
      font-size: 3rem;
    }
    .text-banner-other {
      font-size: 3rem;
    }
  }

  .choose__title {
    font-size: 3rem;
  }

  .pillars__title {
    font-size: 3rem;
  }

  .phases__title {
    font-size: 3rem;
  }

  .title__question {
    font-size: 2rem;
  }

  .section {
    .right {
      padding-left: 50px !important;
    }
    .section__container__text {
      h4 {
        font-size: 2rem !important;
      }
      p {
        font-size: 1.2rem !important;
      }
    }
  }
}

/* /------------------- XL > 1536px / DESKTOP EXTRA LARGE -------------------/ */
@media (min-width: $xl) {
  .banner_container {
    .text-banner-home {
      font-size: 4rem;
    }
    .text-banner-other {
      font-size: 4rem;
    }
  }
  .banner_container_carousel {
    .text-banner-home {
      font-size: 4rem;
    }
    .text-banner-other {
      font-size: 4rem;
    }
  }
  .choose__title {
    font-size: 4rem;
  }
  .pillars__title {
    font-size: 4rem;
  }
  .phases__title {
    font-size: 4rem;
  }

  .title__question {
    font-size: 3rem;
  }
}

.text-left {
  text-align: left !important;
}

.language-mobile {
  position: absolute !important;
  top: 0 !important;
  width: 150px !important;
  right: 0 !important;
  margin: 25px !important;
}

.banner_customer {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  width: 100%;
  min-height: 1000px;
}

.banner_container {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  padding-top: 150px;
  height: calc(100vh - 150px);
  width: 100%;
  position: relative;
  display: flex;
  &.align-items-center {
    align-items: center;
  }
  .text-banner-home {
    color: #fff;
    font-weight: 300;
  }
  .text-banner-other {
    color: #fff;
    font-weight: 500;
  }
  div {
    z-index: 1;
    color: #fff;
    letter-spacing: 0.5px;
  }
  &::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.content__banner__home {
  padding-top: 100px;
  height: calc(100vh - 150px);
  width: 100%;
  position: relative;
  display: flex;
}

.banner_container_carrousel {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  padding-top: 150px;
  height: auto;
  max-height: calc(100vh - 150px);
  width: 100%;
  position: relative;
  display: flex;
  &.align-items-center {
    align-items: center;
  }
  .text-banner-home {
    color: #fff;
    font-weight: 300;
  }
  .text-banner-other {
    color: #fff;
    font-weight: 500;
  }
  div {
    z-index: 1;
    color: #fff;
    letter-spacing: 0.5px;
  }
}

.slide_carousel {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.choose__title {
  color: $primary;
  font-weight: 300;
  text-align: right;
  margin-top: 0px;
}

.pillars__title {
  color: #fff;
  font-weight: 300;
  text-align: right;
  margin-top: 0px;
  z-index: 1;
}

.text-align-left {
  text-align: left;
}

.phases__title {
  color: $primary;
  font-weight: 300;
  text-align: left;
  margin-top: 0px;
}

.title__question {
  font-weight: 700;
  text-align: left;
  font-size: 1.2rem;
  color: $secondary;
}

footer {
  .footer__content {
    background: $footer;
    color: #fff;
    padding: 50px 0;
    div {
      margin-top: 0;
    }
    .footer__logo {
      height: 50px;
    }
    .container__items__footer {
      width: 100%;
      padding: 30px 0;
      .calls_logo {
        height: 15px;
        padding-right: 2px;
      }
    }
    h4 {
      font-size: 1rem;
      font-weight: 400;
    }
    p {
      line-height: 22.5px;
      font-size: 0.8rem;
      font-weight: 400;
      letter-spacing: 2;
      max-width: 500px;
      text-align: center;
      a {
        text-decoration: none;
        color: #fff;
        transition: 0.5s;
        &:hover {
          opacity: 0.65;
        }
      }
    }
  }
  .footer__redes_sociales {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }
}

.container__btn__whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0dc152;
  cursor: pointer;
  box-shadow: 0 1px 6px rgb(0 0 0 / 6%), 0 2px 32px rgb(0 0 0 / 16%);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1024;
  &:hover {
    opacity: 0.85;
  }
  a {
    color: #fff;
    svg {
      font-size: 36px;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.container__btn__oferta {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 25px;
  border-radius: 5px;
  background-color: $brown-primary;
  cursor: pointer;
  box-shadow: 0 1px 6px rgb(0 0 0 / 6%), 0 2px 32px rgb(0 0 0 / 16%);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1024;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  &:hover {
    background-color: $primary;
    a {
      color: #fff;
    }
  }
}
.content__btn__banner {
  display: flex;
  .content__btn__reserva {
    background-color: #fff;
    padding: 10px 35px;
    border-radius: 25px;
    border: 2px solid $secondary;
    transition: 0.5s;
    text-decoration: none;
    &:hover {
      background-color: $secondary;
      p {
        color: #fff;
      }
    }
    p {
      color: $secondary;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.75;
      margin: 0px;
    }
  }
}

.basic__padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.reduce__contentx1 {
  padding: 0 5% !important;
}

.reduce__contentx2 {
  padding: 0 10% !important;
}

.reduce__contentx3 {
  padding: 0 15% !important;
}

.reduce__contentx4 {
  padding: 0 20% !important;
}

.reduce__contentx5 {
  padding: 0 25% !important;
}

.reduce__content__left {
  padding-left: 10% !important;
}

.reduce__content__right {
  padding-right: 10% !important;
}

.text__content__blue {
  color: $secondary;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
}

.text__content__subluxacion {
  color: $primary;
  font-weight: 400;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0px;
}

.icon__logo {
  height: 75px;
}

.description__item {
  color: $item_text;
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
}

.description__item__photo {
  color: $item_text;
  font-size: 1rem;
  text-align: left;
  font-weight: 300;
}

.description__item__center {
  color: $item_text;
  font-size: 1rem;
  text-align: left;
  font-weight: 300;
}

.description__item__chiropractic {
  color: $item_text;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 300;
  margin-bottom: 25px;
}

.text-align-right {
  text-align: right !important;
}

.item__enumeration {
  font-size: 1.2rem;
  font-weight: 700;
  padding-right: 5px;
}

.description__item__subluxacion {
  color: $secondary;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 300;
  margin-bottom: 25px;
}

.center-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;
}

.description__item__video {
  color: $item_text;
  font-size: 0.8rem;
  text-align: left;
  font-weight: 300;
  font-style: italic;
}

.description__question {
  color: $item_text;
  font-size: 1.3rem;
  text-align: left;
  font-weight: 300;
}

.content-img-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0px;
  .img__items {
    max-width: 100%;
    width: auto;
    max-height: 350px;
  }
}
.container__img__items__subluxation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .img__items__subluxation {
    max-width: 100%;
    width: auto;
    max-height: 350px;
    padding-top: 25px;
  }

  .img__zoom {
    z-index: 1024;
    transition: 1s;
    &:hover {
      transform: scale(3) !important;
    }
  }
}

.menu__navbar {
  cursor: pointer !important;
}

.item__menu__navbar {
  text-decoration: none;
  color: #727170;
}

.content__description__item {
  display: flex !important;
  align-items: flex-start !important;
}

.content__icon__item {
  min-height: 150px;
}

.btn__item {
  width: 100%;
  margin-top: 25px;
  button {
    width: 100%;
    background-color: #fff;
    padding: 5px 35px;
    border-radius: 25px;
    border: 2px solid $secondary;
    transition: 0.5s;
    text-transform: none;
    color: $secondary;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.75;
    margin: 0px;
    cursor: pointer;
    &:hover {
      background-color: $secondary;
      color: #fff;
    }
  }
}

.btn__item__contact {
  width: auto;
  margin-top: 25px;
  button {
    width: 100%;
    background-color: #fff;
    padding: 5px 35px;
    border-radius: 25px;
    border: 2px solid $secondary;
    transition: 0.5s;
    text-transform: none;
    color: $secondary;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.75;
    margin: 0px;
    cursor: pointer;
    &:hover {
      background-color: $secondary;
      color: #fff;
    }
  }
}

.video__config {
  max-width: 640px;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.stand-text {
  font-weight: 700;
}

.btn__item__contact__chiropractic {
  width: auto;
  margin-top: 25px;
  button {
    width: auto;
    background-color: #fff;
    padding: 5px 35px;
    border-radius: 25px;
    border: 2px solid $secondary;
    transition: 0.5s;
    text-transform: none;
    color: $secondary;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.75;
    margin: 0px;
    cursor: pointer;
    &:hover {
      background-color: $secondary;
      color: #fff;
    }
  }
  &.btn__active {
    button {
      background-color: $secondary;
      color: #fff;
    }
  }
}

.item__padding {
  padding: 10px;
}

.background__sky__blue {
  background-color: $sky_blue;
  position: relative;
}

.background__blue {
  background-color: #5c8caa;
}

.background__blue__carrousel {
  background-color: #4c84a7;
  overflow-x: clip;
}

.line__right__blue {
  margin-top: 35px;
  border-right: 4px solid $secondary;
  padding-right: 25px;
}

.line__right__white {
  margin-top: 35px;
  border-right: 4px solid #fff;
  padding-right: 25px;
  position: relative;
}

.line__left__blue {
  margin-top: 35px;
  border-left: 4px solid $secondary;
  padding-left: 25px;
}

.image__item__choose {
  height: 250px;
  width: auto;
}

.image__item__phases {
  height: auto;
  width: 100%;
}

.title__primary {
  color: $secondary;
  font-weight: 700;
}

.padding__customer {
  padding: 25px;
}

.text__align__right {
  text-align: right;
}

.text-align-center {
  text-align: center !important;
}

.content__description__customer {
  padding: 0 25px;
  margin-top: 0px;
}

.item__question {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%),
    0px 1px 3px 0px rgb(0 0 0 / 0%) !important;
}

.section__pillars {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  padding: 50px 0;
  width: 100%;
  position: relative;
  display: flex;
  background-image: url("../../../public/assets/image/banner_pillars.jpg");
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(76, 132, 167, 0.87);
  }
  .content__item__pillars {
    position: relative;
  }
  .circle__pillars {
    padding: 25px;
    background-color: #fff;
    border-radius: 50%;
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $item_text;
    font-size: 0.8rem;
    font-weight: 300;
  }
}

.pin__img {
  height: 30px;
  padding-right: 15px;
}

.separator {
  background-color: $secondary;
  width: 25%;
  height: 2px;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
  .leaflet-bottom.leaflet-right {
    display: none;
  }
}

.image__banner__solutions {
  width: 100%;
  height: auto;
}

.star__img {
  width: 20px;
  height: auto;
  margin-right: 15px;
}

.container__person {
  background-color: #fff;
  width: 100%;
  padding: 0 50px;
  height: auto;
  border-radius: 25px;
  min-height: 400px;
}

.slide__person {
  transform: scale(0.75) !important;
  &.swiper-slide-active {
    transform: scale(1) !important;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  @media (max-width: 768px) {
    color: $secondary !important;
  }
}

.swiper__center {
  .swiper-wrapper {
    max-height: 500px !important;
    img {
      width: 100%;
    }
  }
}

.content__blog {
  padding: 25px 0px;
  .img__blog {
    background-attachment: scroll;
    background-size: cover;
    height: 350px;
    width: 100%;
    border-radius: 25px;
    transition: 0.5s;
    &:hover {
      transform: scale(0.95);
    }
  }

  .content__text__blog {
    padding: 5%;
    .fecha__blog {
      color: $primary;
      font-size: 0.9rem;
      text-align: left;
      font-weight: 300;
    }

    .title__blog {
      color: $primary;
      font-size: 1.2rem;
      text-align: left;
      font-weight: 600;
      margin-bottom: 25px;
      transition: 0.5s;
      &:hover {
        color: $secondary;
      }
    }

    .resumen__blog {
      color: $primary;
      font-size: 1rem;
      text-align: left;
      font-weight: 300;
    }
  }
}

.title__detail__blog {
  color: $primary;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
}

.date__detail__blog {
  color: $primary;
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
}

.img__detail__blog {
  width: 100%;
}

.content__detail__blog {
  color: $primary;
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
  padding: 5px 0px;
}

.subtitle__detail__blog {
  color: $primary;
  font-size: 2rem;
  text-align: left;
  font-weight: 600;
}

.btn__detail__blog {
  padding: 35px 15px 15px 15px;
  font-size: 1.5rem;
  text-decoration: none;
  color: $secondary;
  transition: 0.5s;
  font-weight: 560;
  &:hover {
    color: $primary;
  }
}

.container__star {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-top: 45px;
  img {
    margin: 0 2.5px;
  }
}

.avatar {
  width: 125px;
  height: 125px;
  background-color: #689f38;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-top: -62.5px;
}
